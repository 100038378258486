import React from "react"
import styled from "styled-components"
import List from "./list"
import SVG from "./svg"
import Img from "gatsby-image"
import Button from "./button"

const InfoBox = styled.div`
  margin-left: ${(p) => (p.align === "right" ? "150px" : "0px")};
  padding: 0 40px;

  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 40px 8px;
  }

  h2 {
    color: ${(props) => props.theme.DarkBlue};
    margin: 10px 0;
    font-size: 3.2em;
    font-family: "interstate-compressed";
  }

  h3 {
    color: ${(props) => props.theme.Blue};
    margin: 10px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 0.15em;
  }

  p {
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 10px 0 20px 0;
    color: ${(props) => props.theme.DarkBlue};
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`

const ImageStyled = styled.div`
  position: relative;
  width: inherit;
  max-width: 450px;
  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
  .img {
    transition: width 600ms;
    width: inherit;
    overflow: hidden;
    max-height: 450px;
    width: 450px;

    @media (max-width: 1450px) {
      max-width: 450px;
      width: 400px;
    }

    @media (max-width: 768px) {
      width: 94vw;
      margin: 0;
    }
  }
`

const FeatureListStyled = styled.div`
  max-width: 960px;
  margin: ${(p) => p.margin || "0px auto"};
  z-index: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  padding: 0px;
  flex-direction: ${(p) => (p.align === "left" ? "row-reverse" : "row")};

  .swave {
    position: absolute;
    z-index: 2;
    top: 100px;
    right: ${(p) => (p.align === "left" ? "auto" : "-80px")};
    left: ${(p) => (p.align === "left" ? "-80px" : "auto")};
    margin: 20px 0 0 0;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    margin: 0;
    font-size: ${(p) => (p.bg ? "4em" : "7.2em")};
    line-height: ${(p) => (p.bg ? "1em" : "60px")};
  }

  h3 {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 50px 0;
  }
`

const FeatureList = ({
  bg,
  align,
  title,
  headline,
  margin,
  sub_heading,
  bullet_points,
  summary,
  image2,
  image,
  button_label,
  wave_color,
  button_link,
}) => {
  let img
  const t = title || headline
  const im = image || image2
  if (im && im.localFile)
    img = (
      <Img className="img" fluid={im.localFile.childImageSharp.fluid} alt={t} />
    )
  if (im && typeof im === "string") img = <img className="img" src={image2} />
  return (
    <FeatureListStyled align={align} bg={bg} margin={margin}>
      {bg ? <div className="blueBG" /> : ""}
      <InfoBox align={align}>
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: t,
          }}
        />
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: sub_heading,
          }}
        />{" "}
        {bg ? <SVG name="SWave" className="swave" color={wave_color} /> : ""}
        <p
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: summary,
          }}
        />
        <List padding="5px 0">
          {bullet_points.map((item, i) => {
            const color = ["green", "orange", "red", "blue"][Math.floor(i % 4)]
            const classes = `bullet ${color}`
            let curr = <span className={classes}>{i + 1}</span>
            return (
              <li key={i}>
                {curr}
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.point,
                  }}
                />
              </li>
            )
          })}
        </List>
      </InfoBox>
      <ImageStyled>
        {bg ? "" : <SVG name="SWave" className="swave" color={wave_color} />}
        {img}
      </ImageStyled>
    </FeatureListStyled>
  )
}

export default FeatureList
