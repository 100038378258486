import React, { useContext } from "react"
import { connect } from "react-redux"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Layout from "../components/layout"
import HeroArea from "../components/hero"
import Heading from "../components/heading"
import SEO from "../components/seo"
import { Row, Col } from "../components/rows"
import Feature from "../components/feature"
import FeatureList from "../components/feature-list"
import ExternalStories from "../components/ext-stories"
import CompactFeature from "../components/compact-feature"
import OpeningBanner from "../components/opening-banner"
import Img from "gatsby-image/withIEPolyfill"
import BottomColorNav from "../components/bottom-color-nav"
import postPath from "../utils/post-path"

const PositionListStyled = styled.div`
  padding: 32px;

  @media (max-width: 1164px) {
    padding: 32px 0;
  }

  .table {
    background: ${(p) => p.theme.LightestBlue};
    text-align: center;
    padding: 40px;
    margin: 0 auto;
    max-width: 1100px;

    @media (max-width: 768px) {
      padding: 10px 10px;
    }
  }

  span {
    display: inline-block;
    width: 33%;
    font-weight: bold;
  }

  a {
    color: ${(p) => p.theme.Blue};
  }

  span.left {
    text-align: left;
  }

  .heading {
    font-size: 2.4em;
    padding: 5px 0;
    color: ${(p) => p.theme.DarkBlue};
    border-bottom: 1px solid ${(p) => p.theme.Blue};
    font-family: "interstate-compressed";
  }

  ul {
    color: ${(p) => p.theme.DarkBlue};
    list-style: none;
    padding: 12px 0;
    margin: 0;
  }

  li {
    padding: 12px 0;
    margin: 0;
    font-size: 1.4em;
  }

  .noPositions {
    padding: 50px 0;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 2em;
    text-align: center;

    @media (max-width: 768px) {
      padding: 50px 10px;
    }
  }

  .footer {
    padding: 10px 0;
    text-align: left;
    font-size: 1.4em;
    color: ${(p) => p.theme.DarkGrey};
    border-top: 1px solid ${(p) => p.theme.Blue};
  }
`

const PositionList = ({ fields, positions }) => {
  const positionsTable =
    positions.length > 0 && !fields.hide_on_site ? (
      <ul>
        {positions.map((p, i) => {
          const title = (
            <Link to={postPath("/position/", p.acf.job_title)}>
              {p.acf.job_title}
            </Link>
          )
          return (
            <li>
              <span className="left">{title}</span>
              <span>{p.acf.location}</span>
              <span>{p.full_time ? "FULL TIME" : "PART TIME"}</span>
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="noPositions">{fields.no_positions}</div>
    )
  return (
    <PositionListStyled>
      <div className="table">
        <div className="heading">
          <span>POSITION</span>
          <span>LOCATION</span>
          <span>HOURS</span>
        </div>
        {positionsTable}
        <div className="footer">{fields.disclaimer}</div>
      </div>
    </PositionListStyled>
  )
}

const IconFeatureStyled = styled.div`
  background-color: ${(p) => p.theme.LightBlue};
  width: 100%;
  padding: 48px 0;

  .inner {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px 0;
    grid-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    height: 165px;
    margin: 10px 0;
    /* max-width: 200px; */
    font-weight: bold;
    width: inherit;
  }

  p {
    text-align: center;
    margin: 10px auto 0;
  }

  h4 {
    margin: 0;
    padding: 0 7px;
    font-size: 1.4em;
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};
  }
  .img {
    height: 85px;
  }
`

const IconFeature = ({ fields }) => {
  const theme = useContext(ThemeContext)
  const ic = fields.icon_feature
  return (
    <IconFeatureStyled>
      <ResponsiveSpacing>
        <Heading>
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: ic.headline,
            }}
          />
          <h3
            style={{ color: theme.DarkBlue }}
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: ic.sub_heading,
            }}
          />
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: ic.summary,
            }}
          />
        </Heading>
        <div className="inner">
          {ic.icons.map((c, i) => {
            return (
              <div className="item">
                <Img
                  fixed={c.icon.localFile.childImageSharp.fixed}
                  className="img"
                  alt={c.caption}
                />
                <h4
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: c.caption,
                  }}
                />
              </div>
            )
          })}
        </div>
      </ResponsiveSpacing>
    </IconFeatureStyled>
  )
}

const CareersStyled = styled.div`
  margin: 0 0 150px 0;

  @media (max-width: 768px) {
    /* .background {
      width: 220%;
      top: -50px;
    }
    .heroH1 .headline {
      height: 30px;
    } */
  }
`

const Careers = ({ fields, news, positions }) => {
  const {
    opening_banner,
    top_feature,
    double_feature,
    features,
    bottom_feature,
    news_header,
  } = fields
  return (
    <CareersStyled>
      <HeroArea fields={fields} />
      <OpeningBanner>
        <div className="inner">
          <ResponsiveSpacing>
            <h2
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening_banner.headline,
              }}
            />
            <p
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening_banner.statement,
              }}
            />
          </ResponsiveSpacing>
        </div>
      </OpeningBanner>
      <ResponsiveSpacing>
        <FeatureList {...top_feature} align="left" margin="150px auto" />
      </ResponsiveSpacing>
      <IconFeature fields={fields} />
      <ResponsiveSpacing>
        <Heading margin="150px 0 100px 0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: double_feature.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: double_feature.sub_heading,
            }}
          />
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: double_feature.summary,
            }}
          />
        </Heading>
        <Row margin="100px auto 50px auto" width="1000px">
          <Col>
            <CompactFeature {...features[0]} align={"left"} />
          </Col>
          <Col>
            <CompactFeature {...features[1]} align={"right"} />
          </Col>
        </Row>
        <PositionList fields={fields.positions_open} positions={positions} />
        <Feature {...bottom_feature} align="right" margin="50px auto" />
        <Heading margin="150px 8px 50px 8px">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: news_header.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: news_header.sub_heading,
            }}
          />
        </Heading>
        <ExternalStories
          articles={news.external_stories.articles.splice(0, 3)}
        />
      </ResponsiveSpacing>
    </CareersStyled>
  )
}

const ConnectedCareers = connect((state) => state)(Careers)

const CareersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      positions: allWordpressWpPositions {
        edges {
          node {
            title
            content
            acf {
              hide_from_view
              job_title
              full_time
              location
              overview
              requirements
              apply_button_label
            }
          }
        }
      }
      news: allWordpressPage(filter: { slug: { eq: "news-page" } }) {
        edges {
          node {
            acf {
              external_stories {
                headline
                articles {
                  headline
                  source_name
                  date
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 360) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  article_link
                }
              }
            }
          }
        }
      }
      page: allWordpressPage(filter: { slug: { eq: "careers-page" } }) {
        edges {
          node {
            title
            acf {
              opening_banner {
                headline
                statement
              }
              hero_area {
                top_line
                headline
                top_line_color
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              top_feature {
                headline
                sub_heading
                summary
                wave_color
                bullet_points {
                  point
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              icon_feature {
                headline
                sub_heading
                summary
                icons {
                  caption
                  icon {
                    localFile {
                      childImageSharp {
                        fixed(height: 85) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
              double_feature {
                headline
                sub_heading
                summary
              }
              features {
                headline
                sub_heading
                summary
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              positions_open {
                headline
                summary
                disclaimer
                no_positions
                hide_on_site
              }
              bottom_feature {
                headline
                sub_heading
                wave_color
                summary
                button_label
                button_link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              news_header {
                headline
                sub_heading
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.page.edges[0].node.acf
  const news = data.news.edges[0].node.acf
  const positions = data.positions.edges
    .map((e) => e.node)
    .filter((n) => !n.acf.hide_from_view)

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title || fields.hero_area.headline}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedCareers fields={fields} news={news} positions={positions} />
      <BottomColorNav />
    </Layout>
  )
}

// <HeroArea fields={fields}>
// 	<div tabIndex="0">
// 		<Button to={fields.hero_area.button_link}>
// 			{fields.hero_area.button_label}
// 		</Button>
// 	</div>
// </HeroArea>

export default CareersPage
