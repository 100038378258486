import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import CTA from "../components/cta"

const ArticleStyled = styled.div`
  padding: 10px 0;
  max-width: 360px;
  margin: 0 auto;
  height: auto;

  @media (max-width: 768px) {
    padding: 0 8px;
  }

  .img {
    max-width: 360px;
    height: 280px;
    width: inherit;
  }

  h3 {
    margin: 5px 0;
    font-size: 1.8em;
    color: ${(p) => p.theme.DarkBlue};
  }

  h4 {
    margin: 5px 0;
    font-size: 1.4em;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${(p) => p.theme.Blue};
  }

  a {
    margin: 15px 0 0 0;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: ${(p) => p.theme.DarkBlue};
  }
`

const Article = ({ children }) => {
  return <ArticleStyled>{children}</ArticleStyled>
}

const ExternalStoriesStyled = styled.div`
  margin: 50px auto 0 auto;
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
  @media (max-width: 1068px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ExternalStories = ({ articles }) => {
  return (
    <ExternalStoriesStyled>
      {articles.map((a, i) => (
        <Article key={i}>
          <a href={a.article_link} target="_blank" rel="noopener noreferrer">
            <Img
              objectFit="contain"
              className="img"
              fluid={a.image.localFile.childImageSharp.fluid}
            />
            <h3
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: a.headline,
              }}
            />
            <h4>{`${a.source_name}, ${a.date}`}</h4>
            <CTA>READ THE FULL ARTICLE</CTA>
          </a>
        </Article>
      ))}
    </ExternalStoriesStyled>
  )
}

export default ExternalStories
