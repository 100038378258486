import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "./svg"
import Button from "./button"

const InfoBox = styled.div`
  width: 100%;
  max-width: 450px;

  h2 {
    max-width: 450px;
    color: ${(props) => props.theme.DarkBlue};
    margin: 10px 0 0 0;
    font-size: 6em;
    font-weight: 800;
    line-height: 0.9em;
    letter-spacing: 2px;
    font-family: "interstate-compressed";
  }

  h3 {
    color: ${(props) => props.theme.Blue};
    margin: 10px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 0.15em;
  }

  p {
    max-width: 450px;
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 10px 0 20px 0;
    color: ${(props) => props.theme.DarkBlue};
  }
`

const ImageStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
  .img {
    transition: width 600ms;
    width: inherit;
    overflow: hidden;
    max-width: 450px;
    img {
      height: inherit;
      width: inherit;
    }

    @media (max-width: 1450px) {
      width: inherit;
      min-height: 350px;
    }

    @media (max-width: 768px) {
      width: 94vw;
    }
  }
`

const FeatureStyled = styled.div`
  max-width: 450px;
  margin: ${(p) => p.margin || "0px auto"};
  z-index: 1;
  display: flex;
  flex-flow: column;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;

  .swave {
    color: transparent;
    position: absolute;
    z-index: 2;
    transform: ${(p) => (p.align === "left" ? "scaleX(-1)" : "scaleX(1)")};
    top: ${(p) => (p.align === "left" ? "60px" : "340px")};
    right: ${(p) => (p.align === "left" ? "auto" : "-80px")};
    left: ${(p) => (p.align === "left" ? "-80px" : "auto")};
    margin: 20px 0 0 0;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`

const Feature = ({
  align,
  title,
  headline,
  sub_heading,
  summary,
  image2,
  image,
  button_label,
  wave_color,
  button_link,
}) => {
  let img
  const t = title || headline
  const im = image || image2
  if (im && im.localFile)
    img = (
      <Img className="img" fluid={im.localFile.childImageSharp.fluid} alt={t} />
    )
  if (im && typeof im === "string") img = <img className="img" src={image2} />
  return (
    <FeatureStyled align={align}>
      <ImageStyled>
        <SVG name="SWave" className="swave" color={wave_color} /> {img}
      </ImageStyled>
      <InfoBox align={align}>
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: t,
          }}
        />
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: sub_heading,
          }}
        />
        <p
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: summary,
          }}
        />{" "}
        {button_label ? <Button link={button_link}>{button_label}</Button> : ""}
      </InfoBox>
    </FeatureStyled>
  )
}

export default Feature
